export const Project = {
  RevenueCat: {
    apple: 'appl_',
    google: 'goog_',
  },
  api: 'https://staging-api.hoxtonmix.com/api/v3/',
  apiAuth: '',
  codepush: {
    android: {
      production: '52_yx7psCRNIW2GePo3LK9dS7pJX7SUUnATWe',
      staging: 'G06ZyOr1R7HaoZ8r-l5BQ9KVFjmdcfPI0N-c9',
    },
    ios: {
      production: 'PgXR9f6H-fZipoZ8Gis9cItBqNlyzazIAAJj5',
      staging: 'Ww7zWeD8eVjMFXb6dcexd2oUWKpr4EBBYHUwH',
    },
  },
  cognito: {
    region: 'eu-west-2',
    userPoolId: 'eu-west-2_3dro99y9o',
    userPoolWebClientId: '7pr4c93i9l470rubfodpbdglm2',
  },
  companyAPI: 'https://staging-api.hoxtonmix.com/api/v3/',
  currency: 'GBP',
  debug: false,
  flagsmith: 'Hc2gntqJyXDZmiBn4r2yjL',
  ga: '',
  headers: {
    'Tenant-ID-Authentication': 1,
  },
  locale: 'en-gb',
  logs: {
    API: false,
    COGNITO: false,
    DATA: false,
    DISPATCHER: false,
    EVENTS: true,
    PUSH_NOTIFICATIONS: false,
    SERVER: false,
    STORAGE: false,
    STORE: false,
  },
  loqate: 'PR28-BF96-PB24-GY46',
}
